<template>
    <v-container fluid>
        <v-layout wrap>
            <v-flex fluid>
                <v-card :loading="loading" outlined style="border: none;">
                    <v-card-title class="px-1 d-flex align-center justify-center">
                        <p class="font-weight-black" style="color: #306AA0;">
                            Coleta de Assinatura
                        </p>
                    </v-card-title>
                    <v-card class="px-1">
                        <v-row cols="12" sm="6" dense>
                            <!-- Escolha do Paciente -->
                            <v-col cols="12" sm="6">
                                <v-autocomplete 
                                    label="Paciente"
                                    outlined 
                                    dense 
                                    chips 
                                    small-chips 
                                    required
                                    readonly
                                ></v-autocomplete>
                            </v-col>
                            <!-- Data de nascimnto -->
                            <v-col cols="12" sm="6">
                                <v-text-field 
                                    label="Data de Nascimento"
                                    outlined 
                                    readonly 
                                    dense 
                                ></v-text-field>
                            </v-col>
                            <!-- Procedimento para associar a assinatura -->
                            <v-col cols="12" sm="6">
                                <v-autocomplete 
                                    label="Escolher Procedimento"
                                    outlined 
                                    dense
                                    chips
                                    small-chips
                                    required
                                    readonly 
                                ></v-autocomplete>
                            </v-col>
                            <!-- Evolução para associar a assinatura -->
                            <v-col cols="12" sm="6">
                                <v-autocomplete 
                                    label="Escolher Evoluçao"
                                    outlined 
                                    dense
                                    chips
                                    small-chips
                                    required
                                    readonly
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                        <!-- Campo para assinar -->
                        <v-col>
                            <VueSignaturePad
                                id="signature"
                                width="100%"
                                height="300px"
                                ref="signaturePad"
                                :options="options"
                                />
                        </v-col>
                        <!-- Botões de Salvar e Limpar -->
                        <v-col>
                                <v-row justify="space-around" style="padding-bottom: 5%;" dense>
                                    <v-btn 
                                        color="#306AA0" 
                                        rounded 
                                        dark 
                                        style="font-weight: bold;"
                                        :loading="loading"
                                        @click="saveSign" 
                                    >
                                        Salvar
                                    </v-btn>
                                    <v-btn 
                                        color="red" 
                                        rounded 
                                        dark
                                        :disabled="loading"
                                        style="font-weight: bold;"
                                        @click="clearSign"
                                   >
                                        Limpar Assinatura
                                    </v-btn>
                                </v-row>
                                <!-- campo incluído apenas para testar se a imagem foi salva. -->
                        <!-- <v-col id="imagem_salva" dense>
                            <img :src="imagemAssinatura" v-if="imagemAssinatura" />
                        </v-col> -->
                        </v-col>
                    </v-card>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>

export default {
    name: "coleta_assinatura",
    created() {
        window.scroll(0,0);
    },
    data: () => ({
        imagemAssinatura: null,
        loading: false,
    }),
    methods: {
        clearSign() {
            this.loading =true
            this.$refs.signaturePad.clearSignature();
            this.loading =false
        },
        saveSign() {
            this.loading = true
            navigator.geolocation.getCurrentPosition((position) => {
            const lat1 = position.coords.latitude;
            const lng1 = position.coords.longitude;
            // const lat2 = -2.472874;
            // const lng2 = -44.178756;
            const lat2 = -2.4767083;  // São Luís Home Care
            const lng2 = -44.1890805; // São Luís Home care
            const R = 6371e3; // raio médio da Terra em metros
            const phi1 = lat1 * Math.PI / 180;
            const phi2 = lat2 * Math.PI / 180;
            const deltaPhi = (lat2 - lat1) * Math.PI / 180;
            const deltaLambda = (lng2 - lng1) * Math.PI / 180;
            const a = Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2) +
                Math.cos(phi1) * Math.cos(phi2) *
                Math.sin(deltaLambda / 2) * Math.sin(deltaLambda / 2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            const d = R * c; // distância entre os dois pontos em metros
            if (d <= 200) { // dentro da área de cobertura
                const { isEmpty, data} = this.$refs.signaturePad.saveSignature('image/png', 0); // 0 menor qualidade e 1 maior qualidade
                if (!isEmpty) {
                    this.imagemAssinatura = data;
                    this.loading =false
                    alert("Assinatura salva com sucesso!");
                } else {
                    this.loading =false
                    alert("Assinatura vazia! Por favor, assine antes de salvar.");
                }
                this.$refs.signaturePad.clearSignature();
                this.signatureImage = data;
                this.loading =false
            } else { // fora da área de cobertura
                this.loading =false
                alert("Você está fora da área de cobertura do local do paciente.");
                this.$refs.signaturePad.clearSignature();
        }
    });
},

    },
}
</script>
<style>
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;

}
.container {
  width: "100%";
  height: auto;
  padding: 10px 16px;
}
</style>